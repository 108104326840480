import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_PURPOSE.PATH,
  },
  {
    name: PAGES.ABOUT_PURPOSE.TITLE,
    absolute_slug: PAGES.ABOUT_PURPOSE.PATH,
  },
];

const CATEGORIES = [
  {
    value: 'all',
    title: 'All Team',
  },
  {
    value: 'adventure',
    title: 'Adventure',
  },
  {
    value: 'snow',
    title: 'Snow',
  },
  {
    value: 'haka_plus',
    title: 'Haka Plus',
  },
  {
    value: 'head_office',
    title: 'Head Office',
  },
];

class AboutPurposePage extends PureComponent {
  constructor() {
    super();
    this.state = {
      selected: 'all',
    };
  }

  render() {
    const { data } = this.props;
    // const OFFICE_PEOPLE = FORMAT_GRAPH_DATA(data.allOfficeCrew);
    // const PEOPLE = FORMAT_GRAPH_DATA(data.allGuides);
    // const EVERYONE = OFFICE_PEOPLE.concat(PEOPLE)
    //   .filter(person => selected === 'all' || person[selected]);

    return (
      <Layout page="about/brand">

        <Helmet
          title="Haka Tours | Our Purpose"
          meta={[
            {
              name: 'description', content: 'Our Māori values & culture is incredibly important to us, which is why we weave Māori culture from New Zealand & adventure into all our small group tours.',
            },
            { name: 'og:title', content: 'Haka Tours | Our Purpose' },
            {
              name: 'og:description', content: 'Our Māori values & culture is incredibly important to us, which is why we weave Māori culture from New Zealand & adventure into all our small group tours.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-purpose/` }]}
        />

        <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-purpose-hero.jpeg?fit=crop&auto=format" modifier="bg-position-30">
          <h2 className="c-heading c-heading--h0">Our Purpose</h2>
        </Banner>
        <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
        <section className="l-section l-people-intro">
          <div className="l-container">
            <Breadcrumbs list={BREADCRUMBS} />
          </div>
          <div className="l-container l-container--small u-text--center mb-3">
            <p>
              As part of Intrepid Travel, our mission is to inspire, create, share and lead the best travel experiences ever.
            </p>
            <p>
              Our small-group tours and our focus on culture are just a couple of ways we integrate purpose within our tours. 
              However, there is so much more work that takes place behind the scenes and will continue to take place as our purpose journey develops. 
              Here’s an insight into the wider ways we incorporate purpose into our day-to-day business activities which in turn provide a more impactful New Zealand travel experience for our travellers...
            </p>
          </div>
        </section>
        <AltPanels items={[
          {
            title: 'B Corp',
            content: `<p>Haka Tours are proud to have joined the B Corp™ community and are one of only a small handful of tourism organisations with this accolade in Aotearoa New Zealand.
            As a B Corp, we prioritise the impact of our decisions on all stakeholders - including employees, customers, the environment and our communities,
            we also meet high standards of social and environmental performance, transparency and accountability.
            </p>
            <p>Discover more about our B Corp status here -  <a href="https://www.intrepidtravel.com/nz/purpose/b-corp" target="_blank">Being a B Corp</a>
            - and thank you to all of you who have supported us in this journey so far.
            </p>
            `,
            image: {
              width: 200,
              height: 475,
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/our-purpose-b-corp.png?w=180&h=427.5&fit=clip&format=auto',
              align: 'center',
            },
          },
          {
            title: 'Tiaki Promise',
            content: `<p>New Zealand is one of the most precious places on earth and we want to keep it that way. 
            Since we began, we have looked to care for our people and places in any way we can.
            We also want our guests to tread lightly as they travel, so here are a few ways in which you will make an impact as you travel with Haka Tours. 
            To further strengthen our commitment we have signed up to <a href="https://hakatours.com/haka-tours-declares-climate-emergency" target="_blank">Tourism Declares</a>, an initiative that supports tourism businesses, organisations and individuals in declaring a climate emergency and taking purposeful action to reduce our carbon emissions.
            </p>
            `,
            image: {
              width: 450,
              height: 450,
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/our-purpose-tiaki.avif?w=450&h=450&fit=crop&auto=format',
              align: 'right',
            },
          },
          {
            title: 'Our Partners',
            content: `<p>We work with a number of organisations who reflect the same values as us. 
            One such example is “<a href="https://www.mynativeforest.com/" target="_blank">MyNativeForest</a>”, where we invest $3 on behalf of every Haka guest that travels with us. 
            My Native Forest started with a simple yet powerful question: How can we plant more native trees? 
            </p>
            <p>However, My Native Forest is not just about planting trees – it's about revolutionising the way they see carbon credits and empowering landowners and businesses alike. 
            With big dreams and a clear mission, they are on a journey to restore New Zealand's landscapes, one native tree at a time and to make sure those responsible are rewarded for it.
            </p>`,
            image: {
              width: 652,
              height: 418,
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/our_purpose_my_native_forest.jpg?w=652&h=418&fit=crop&auto=format',
              align: '',
            },
          },
          {
            title: 'Tips on Tour',
            content: `<p>We strongly encourage each and every one of our guests to be a responsible traveller whilst on tour with us – here are just a few examples of how you can take action:
            <ol>
              <li>Reducing your single-use plastic consumption – so carry a re-usable water bottle with you to use.</li>
              <li>Leave only footprints – be sure to take all rubbish home with you when out on walks etc.</li>
              <li>Support locally-owned businesses where possible.</li>
              <li>Learn about the local culture and language (Te Reo Māori).</li>
              <li>Preserve the natural environment by staying on designated paths and trails.</li>
            </ol>
            </p>
            <p>Discover more <a href="/blog/responsible-travel-tips-for-your-next-holiday/" target="_blank">Responsible Travel Tips For Your Next Holiday – Haka Tours Blog</a> or on <a href="https://peakadventure.sharepoint.com/:w:/r/sites/IntrepidDMCNewZealand/_layouts/15/Doc.aspx?sourcedoc=%7BF3ED8181-9C1C-4A83-8CC7-2C639A20F423%7D&file=Purpose%20Section%20of%20the%20website.docx&action=default&mobileredirect=true&DefaultItemOpen=1" target="_blank">Intrepid’s Responsible Travel</a> webpage.</p>
            `,
            image: {
              width: 652,
              height: 418,
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/our-purpose-tour.jpeg?w=652&h=418&fit=crop&auto=format',
              align: '',
            },
          },
          {
            title: 'Our Culture',
            content: `<p>
            Our mission is to foster deeper connections between our manuhiri (guests), our land and our culture through exceptional travel experiences, and our name, Haka Tours was chosen to reflect of this purpose and the cultural education that underpins all our tours. 
            However, calling ourselves Haka Tours means we have a huge responsibility to live up to the mana (spiritual power) that this name deserves, by ensuring we operate a world-class tourism business. 
            <a href="/about-us/our-culture/" target="_blank">Discover more about our culture</a> and why it’s important to us.
            </p>
            `,
            image: {
              width: 652,
              height: 418,
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/our-purpose-culture.jpg?w=652&h=418&fit=crop&auto=format',
              align: '',
            },
          },
          {
            content: `<p>
            <i>As members of  New Zealand's <a href="https://www.tia.org.nz/" target="_blank">Tourism Industry Aotearoa</a></i> (TIA) we are proud signatories of the Tourism Sustainability Commitment. 
              The programme enables individual tourism businesses to contribute to TIA's <i><a href="https://sustainabletourism.nz/about-us/about-tourism-sustainability-commitment/vision-and-values/" target="_blank">Vision</a></i> in the following ways:
            </p>
            <ol>
              <li>_Have a sustainability plan with goals for all twelve_<i><a href="https://www.sustainabletourism.nz/about-us/about-tourism-sustainability-commitment/components/" target="_blank">Commitments</a></i></li>
              <li>Make measurable progress towards these goals</li>
              <li>Embed sustainability into business systems, processes and plans</li>
              <li>Provide an annual return with an update on progress</li>
              <li>Communicate with communities and visitors about sustainability efforts</li>
            </ol>
            `,
            image: {
              title: 'Example image title',
              url: 'https://hakatours-staging.imgix.net/000/CSR_logo.png?w=400&h=400&fit=crop&auto=format',
              align: 'center',
            },
          }
        ]}
        />

        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <p>&nbsp;</p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default AboutPurposePage;